import { ClientCount } from "../redux/@types/clients";

export const formatCounts = (licenses: any) => {
    let formatted: ClientCount[] = [];
    if (licenses.licences_customers) licenses.licences_customers.forEach((l: any) => formatted.push({
        ...l,
        remaining: l.available_reports, classification_id: l.classification_id, client_id: '',
    }));
    if (licenses.licences) licenses.licences.forEach((l: any) => formatted.push({
        ...l,
        remaining: l.available_reports, classification_id: l.classification_id, client_id: l.client_id
    }));
    return formatted;
}