import { Epic, combineEpics } from "redux-observable";
import { catchError } from "rxjs";
import { cognitoInitEpic } from "./cognitoInitEpic";
import { getClientsEpic } from "./getClientsEpic";
import { createClientEpic } from "./createClientEpic";
import { editClientEpic } from "./editClientEpic";
import { deleteClientEpic } from "./deleteClientEpic";
import { getUsersEpic } from "./getUsersEpic";
import { createUserEpic } from "./createUserEpic";
import { editUserEpic } from "./editUserEpic";
import { deleteUserEpic } from "./deleteUserEpic";
import { userInitEpic } from "./userInitEpic";
import { getClassificationsEpic } from "./getClassificationsEpic";
import { createAnalysisEpic } from "./createAnalysisEpic";
import { updateAnalysisEpic } from "./updateAnalysisEpic";
import { getReportsEpic } from "./getReportsEpic";
import { createReportEpic } from "./createReportEpic";
import { getAnalysesEpic } from "./getAnalysesEpic";
import { getAnalysisEpic } from "./getAnalysisEpic";
import { getAnalysesCountsEpic } from "./getAnalysesCountsEpic";
import { downloadReportEpic } from "./downloadReportEpic";
import { deleteResultPathEpic } from "./deleteResultPathEpic";
import { duplicateAnalysisEpic } from "./duplicateAnalysisEpic";
import { submitAnalysisEpic } from "./submitAnalysisEpic";
import { getClientsCountsEpic } from "./getClientsCountsEpic";
import { editClientCountsEpic } from "./editClientCountsEpic";
import { resetPasswordEpic } from "./resetPasswordEpic";
import { getLicenseTypesEpic } from "./getLicenseTypesEpic";
import { getClientLicencesEpic } from "./getClientLicencesEpic";
import { purchaseProductEpic } from "./purchaseProductEpic";
import { purchaseFulfilmentEpic } from "./purchaseFulfilmentEpic";


const epics: Epic[] = [
    // Cognito
    cognitoInitEpic,
    // Clients
    getClientsEpic,
    getClientsCountsEpic,
    createClientEpic,
    editClientEpic,
    editClientCountsEpic,
    deleteClientEpic,
    // Users
    getUsersEpic,
    createUserEpic,
    editUserEpic,
    resetPasswordEpic,
    deleteUserEpic,
    userInitEpic,
    // Forms
    getClassificationsEpic,
    createAnalysisEpic,
    updateAnalysisEpic,
    deleteResultPathEpic,
    duplicateAnalysisEpic,
    submitAnalysisEpic,
    // Reports
    getReportsEpic,
    createReportEpic,
    downloadReportEpic,
    // Analyses
    getAnalysesEpic,
    getAnalysisEpic,
    getAnalysesCountsEpic,
    // Licenses
    getLicenseTypesEpic,
    getClientLicencesEpic,
    purchaseProductEpic,
    purchaseFulfilmentEpic,
];

const rootEpic: Epic = (
    action$, state$, dependencies
) => combineEpics(...epics)(action$, state$, dependencies).pipe(
    catchError((error, source) => {
        console.log(error);
        return source;
    })
);

export default rootEpic;