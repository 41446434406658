import { ClientCount } from "../@types/clients";


const clientsApi = {
    getClients: async (params: {
        apiGatewayUrl: string,
        token: string
    }) => {
        const { apiGatewayUrl, token } = params;
        const res = await fetch(
            `${apiGatewayUrl}/clients`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            }
        );
        return await res.json()
    },
    createClient: async (params: {
        apiGatewayUrl: string,
        token: string,
        clientName: string,
    }) => {
        const { apiGatewayUrl, token, clientName } = params;
        const body = JSON.stringify({ clientName: clientName })
        const res = await fetch(
            `${apiGatewayUrl}/clients`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body
            }
        );
        return await res.json()
    },
    editClient: async (params: {
        apiGatewayUrl: string,
        token: string,
        clientId: string,
        clientName: string,
    }) => {
        const { apiGatewayUrl, token, clientId, clientName } = params;
        const body = JSON.stringify({ clientName })
        const res = await fetch(
            `${apiGatewayUrl}/clients/${clientId}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body
            }
        );
        return await res.json()
    },
    deleteClient: async (params: {
        apiGatewayUrl: string,
        token: string,
        clientId: string
    }) => {
        const { apiGatewayUrl, token, clientId } = params;
        const res = await fetch(
            `${apiGatewayUrl}/clients/${clientId}`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            }
        );
        return await res.json()
    },
    editClientCounts: async (params: {
        apiGatewayUrl: string,
        token: string,
        clientId: string,
        counts: ClientCount[],
    }) => {
        const { apiGatewayUrl, token, clientId, counts } = params;
        const body = JSON.stringify({ counts });
        const res = await fetch(
            `${apiGatewayUrl}/clients/counts/${clientId}`,
            // `http://localhost:2000/clients/counts/${clientId}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body
            }
        );
        return await res.json()
    },
};

export default clientsApi;