import produce, { Draft } from "immer";
import { getType } from "typesafe-actions";
import { RootAction } from "../actions";
import { ConstantsState } from "../@types/constants";
import { constantsActions} from "../actions/constantsActions";


const initialConstantsState: ConstantsState = {
    apiGatewayUrl: process.env.API_GATEWAY_URL || "",
    cognitoDomain: process.env.COGNITO_DOMAIN || "",
    cognitoAppClientId: process.env.COGNITO_APP_CLIENT_ID || "",
    cognitoUserPoolId: process.env.COGNITO_USER_POOL_ID || "",
    awsRegion: process.env.AWS_REGION || "",
    licenseApiKey: process.env.LICENSE_API_KEY || "",
    paymentApiKey: process.env.PAYMENT_API_KEY || "",
    pdsContactUsUrl: process.env.PDS_CONTACT_US_URL || "",
    pdsClassificationId: process.env.PDS_CLASSIFICATION_ID || "",
    showStore: process.env.SHOW_STORE || "",
}

const constantsReducer = produce(
    (draft: Draft<ConstantsState>, action: RootAction) => {
        switch (action.type) {
            case getType(constantsActions.set):
                {
                    draft = action.payload;
                }
                return;
        }
    },
    initialConstantsState
);

export default constantsReducer;