import { Epic } from "redux-observable";
import { switchMap, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { IState, Dependencies } from "../@types";
import { RootAction } from "../actions";
import { isActionOf } from "typesafe-actions";
import { Auth } from "aws-amplify";
import { licensesActionsAsync } from "../actions/licensesActions";
import { usersActionsAsync } from "../actions/usersActions";


export const getLicenseTypesEpic: Epic<
    RootAction,
    RootAction,
    IState,
    Dependencies
> = (action$, state$, dependencies) => {
    return action$.pipe(
        filter(isActionOf([
            licensesActionsAsync.getLicenseTypes.request,
            usersActionsAsync.userInit.success
        ])),
        switchMap(action => {
            return new Observable<RootAction>(observer => {
                const state = state$.value as IState;
                const { apiGatewayUrl, licenseApiKey } = state.constants;
                async function getLicenseTypes() {
                    const session = await Auth.currentSession();
                    const token = session.getIdToken().getJwtToken();

                    const licenseTypes = await dependencies.licensesApi.getLicenseTypes({
                        apiGatewayUrl,
                        token,
                        licenseApiKey,
                    });
                    observer.next(licensesActionsAsync.getLicenseTypes.success({ licenseTypes: licenseTypes.licence_types }));
                }
                getLicenseTypes().catch(error => {
                    console.log(error);
                    observer.next(licensesActionsAsync.getLicenseTypes.failure({ error }));
                });
            });
        })
    );
}