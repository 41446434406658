import produce, { Draft } from "immer";
import { getType } from "typesafe-actions";
import { RootAction } from "../actions";
// import { LicensesState, Licenses } from "../@types/licenses";
import { LicensesState, LicenseTypes, LicenseType } from "../@types/licenses";
import { licensesActions, licensesActionsAsync } from "../actions/licensesActions";


const initialLicensesState: LicensesState = {
    licenseTypes: {},
}

const licensesReducer = produce(
    (draft: LicensesState, action: RootAction) => {
        switch (action.type) {
            case getType(licensesActionsAsync.getLicenseTypes.success):
                {
                    let formatted: LicenseTypes = {};
                    const { licenseTypes } = action.payload;
                    licenseTypes.forEach((l: LicenseType) => formatted[l.type] = l);
                    draft.licenseTypes = formatted;
                }
                return;
            case getType(licensesActionsAsync.purchaseProduct.success):
                {
                    const { redirect_url } = action.payload;
                    window.open(redirect_url, '_self');
                }
                return;
        }
    },
    initialLicensesState
);

export default licensesReducer;