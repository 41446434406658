import {
    createAction,
    createAsyncAction
} from "typesafe-actions";
import { Client, ClientCount } from "../@types/clients";


export const clientsActions = {}

export const clientsActionsAsync = {
    getClients: createAsyncAction(
        '@clients/getClients/request',
        '@clients/getClients/success',
        '@clients/getClients/failure',
    )<
        {},
        { clients: Client[] },
        {}
    >(),
    createClient: createAsyncAction(
        '@clients/createClient/request',
        '@clients/createClient/success',
        '@clients/createClient/failure',
    )<
        { clientName: string },
        { client: Client },
        {}
    >(),
    editClient: createAsyncAction(
        '@clients/editClient/request',
        '@clients/editClient/success',
        '@clients/editClient/failure',
    )<
        { clientId: string, clientName: string },
        { client: Client },
        {}
    >(),
    deleteClient: createAsyncAction(
        '@clients/deleteClient/request',
        '@clients/deleteClient/success',
        '@clients/deleteClient/failure',
    )<
        { clientId: string },
        { clientId: string },
        {}
    >(),
    getClientsCounts: createAsyncAction(
        '@clients/getClientsCounts/request',
        '@clients/getClientsCounts/success',
        '@clients/getClientsCounts/failure',
    )<
        {},
        { counts: any[] },
        {}
    >(),
    editClientCounts: createAsyncAction(
        '@clients/editClientCounts/request',
        '@clients/editClientCounts/success',
        '@clients/editClientCounts/failure',
    )<
        { licence: any },
        { counts: any[] },
        {}
    >(),
}