import { Epic } from "redux-observable";
import { switchMap, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { IState, Dependencies } from "../@types";
import { RootAction } from "../actions";
import { isActionOf } from "typesafe-actions";
import { licensesActionsAsync } from "../actions/licensesActions";


export const purchaseFulfilmentEpic: Epic<
    RootAction,
    RootAction,
    IState,
    Dependencies
> = (action$, state$, dependencies) => {
    return action$.pipe(
        filter(isActionOf(licensesActionsAsync.purchaseFulfil.request)),
        switchMap(action => {
            return new Observable<RootAction>(observer => {
                const state = state$.value as IState;
                const { apiGatewayUrl, paymentApiKey } = state.constants;
                const { sessionId } = action.payload;
                async function purchaseFulfil() {
                    const result = await dependencies.licensesApi.checkoutFulfil({
                        apiGatewayUrl,
                        paymentApiKey,
                        sessionId,
                    });
                    observer.next(licensesActionsAsync.purchaseFulfil.success({}));
                }
                purchaseFulfil().catch(error => {
                    console.log(error);
                    observer.next(licensesActionsAsync.purchaseFulfil.failure({ error }));
                });
            });
        })
    );
}