

const licensesApi = {
    getLicenseTypes: async (params: {
        apiGatewayUrl: string,
        token: string,
        licenseApiKey: string,
    }) => {
        const { apiGatewayUrl, token, licenseApiKey } = params;
        const res = await fetch(
            `${apiGatewayUrl}/licences/types?is_enabled=true&is_show_ui=true`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    // "Authorization": `Bearer ${token}`,
                    'X-API-KEY': `${licenseApiKey}`,
                }
            }
        );
        return await res.json()
    },
    getCustomerLicenses: async (params: {
        apiGatewayUrl: string,
        token: string,
        licenseApiKey: string,
    }) => {
        const { apiGatewayUrl, token, licenseApiKey } = params;
        const res = await fetch(
            `${apiGatewayUrl}/licences/customers`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    // "Authorization": `Bearer ${token}`,
                    'X-API-KEY': `${licenseApiKey}`,
                }
            }
        );
        return await res.json()
    },
    getClientLicenses: async (params: {
        apiGatewayUrl: string,
        token: string,
        licenseApiKey: string,
        clientId: string
    }) => {
        const { apiGatewayUrl, token, licenseApiKey, clientId } = params;
        const res = await fetch(
            `${apiGatewayUrl}/licences/clients?client_id=${clientId}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    // "Authorization": `Bearer ${token}`,
                    'X-API-KEY': `${licenseApiKey}`,
                }
            }
        );
        return await res.json()
    },
    addClientLicences: async (params: {
        apiGatewayUrl: string,
        token: string,
        licenseApiKey: string,
        licence: any
    }) => {
        const { apiGatewayUrl, token, licenseApiKey, licence } = params;
        const res = await fetch(
            `${apiGatewayUrl}/licences/clients`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    // "Authorization": `Bearer ${token}`,
                    'X-API-KEY': `${licenseApiKey}`,
                },
                body: licence,
            }
        );
        return await res.json()
    },
    addCustomerLicences: async (params: {
        apiGatewayUrl: string,
        licenseApiKey: string,
        licence: any
    }) => {
        const { apiGatewayUrl, licenseApiKey, licence } = params;
        const body = JSON.stringify(licence);
        const res = await fetch(
            `${apiGatewayUrl}/licences/customers`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'X-API-KEY': `${licenseApiKey}`,
                },
                body: licence,
            }
        );
        return await res.json()
    },
    purchaseProduct: async (params: {
        apiGatewayUrl: string,
        paymentApiKey: string,
        product: any
    }) => {
        const { apiGatewayUrl, paymentApiKey, product } = params;
        const body = JSON.stringify(product);
        const res = await fetch(
            `${apiGatewayUrl}/payments/checkout-session`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'X-API-KEY': `${paymentApiKey}`,
                },
                body,
            }
        );
        return await res.json();
    },
    getPaymentInformation: async (params: {
        apiGatewayUrl: string,
        paymentApiKey: string,
        sessionId: string,
    }) => {
        const { apiGatewayUrl, paymentApiKey, sessionId } = params;
        const res = await fetch(
            `${apiGatewayUrl}/payments/checkout-session?session_id=${sessionId}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'X-API-KEY': `${paymentApiKey}`,
                },
            }
        );
        return await res.json();
    },
    checkoutFulfil: async (params: {
        apiGatewayUrl: string,
        paymentApiKey: string,
        sessionId: string,
    }) => {
        const { apiGatewayUrl, paymentApiKey, sessionId } = params;
        const body = JSON.stringify({ session_id: sessionId });
        const res = await fetch(
            `${apiGatewayUrl}/payments/checkout-fulfill`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'X-API-KEY': `${paymentApiKey}`,
                },
                body
            }
        );
        return await res.json();
    },
};

export default licensesApi;