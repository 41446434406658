import { Epic } from "redux-observable";
import { switchMap, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { IState, Dependencies } from "../@types";
import { RootAction } from "../actions";
import { isActionOf } from "typesafe-actions";
import { licensesActionsAsync } from "../actions/licensesActions";
import { clientsActionsAsync } from "../actions/clientsActions";
import { Auth } from "aws-amplify";
import { formatCounts } from "../../utils/formatData";

export const getClientLicencesEpic: Epic<
    RootAction,
    RootAction,
    IState,
    Dependencies
> = (action$, state$, dependencies) => {
    return action$.pipe(
        filter(isActionOf([
            licensesActionsAsync.getClientLicences.request,
        ])),
        switchMap(action => {
            return new Observable<RootAction>(observer => {
                const state = state$.value as IState;
                const { apiGatewayUrl } = state.constants;
                const { clientId } = action.payload;
                const user = state.users.user;
                const licenseApiKey = state.constants.licenseApiKey;
                async function getClientLicences() {
                    const session = await Auth.currentSession();
                    const token = session.getIdToken().getJwtToken();

                    if (!user) throw "No user";

                    const counts = await dependencies.licensesApi.getClientLicenses({
                        apiGatewayUrl,
                        token,
                        licenseApiKey,
                        clientId,
                    });
                    const formatted = formatCounts(counts);
                    observer.next(clientsActionsAsync.getClientsCounts.success({ counts: formatted }));
                }
                getClientLicences().catch(error => {
                    console.log(error);
                    observer.next(licensesActionsAsync.getClientLicences.failure({ error }));
                });
            });
        })
    );
}