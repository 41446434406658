import { Analysis } from "../@types/forms";


const formsApi = {
    getClassifications: async (params: {
        apiGatewayUrl: string,
        token: string,
    }) => {
        const { apiGatewayUrl, token } = params;
        const resTera = await fetch(
            `${apiGatewayUrl}/classifications?major_type=TERA`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            }
        );
        const tera = await resTera.json();
        const resAhvva = await fetch(
            `${apiGatewayUrl}/classifications?major_type=AHVVA`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            }
        );
        const ahvva = await resAhvva.json();
        let classifications: { [classificationId: string]: any } = {}
        tera.forEach((classification: any) => {
            try {
                classifications[classification.classification_id] = {
                    classificationId: classification.classification_id,
                    description: classification.description,
                    geoData: classification.geo_data,
                    majorType: classification.major_type,
                    surveyData: JSON.parse(classification.survey_data),
                }
            } catch (e) {
                console.log("Error tera", classification, e);
            }
        });
        ahvva.forEach((classification: any) => {
            try {
                classifications[classification.classification_id] = {
                    classificationId: classification.classification_id,
                    description: classification.description,
                    geoData: classification.geo_data,
                    majorType: classification.major_type,
                    surveyData: JSON.parse(classification.survey_data),
                }
            } catch (e) {
                console.log("Error ahvva", classification, e)
            }
        });
        return classifications;
    },
    createAnalysis: async (params: {
        apiGatewayUrl: string,
        token: string,
        userId: string,
        clientId: string,
        classificationId: string,
    }) => {
        const { apiGatewayUrl, token, userId, clientId, classificationId } = params;
        const body = JSON.stringify({
            user_id: userId,
            client_id: clientId,
            classification_id: classificationId,
            description: "Untitled"
        });
        const res = await fetch(
            `${apiGatewayUrl}/analyses`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body
            }
        );
        let data = await res.json();

        return data;
    },
    updateAnalysis: async (params: {
        apiGatewayUrl: string,
        token: string,
        analysis: Analysis,
    }) => {
        const { apiGatewayUrl, token, analysis } = params;
        const body = JSON.stringify(analysis);
        const res = await fetch(
            `${apiGatewayUrl}/analyses/${analysis.analysis_id}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    // "X-Amz-Invocation-Type": "'Event'",
                    "Authorization": `Bearer ${token}`
                },
                body
            }
        );
        return null;
    },
};

export default formsApi;