import { Epic } from "redux-observable";
import { switchMap, filter } from "rxjs/operators";
import { Observable } from "rxjs";
import { IState, Dependencies } from "../@types";
import { RootAction } from "../actions";
import { isActionOf } from "typesafe-actions";
import { licensesActionsAsync } from "../actions/licensesActions";


export const purchaseProductEpic: Epic<
    RootAction,
    RootAction,
    IState,
    Dependencies
> = (action$, state$, dependencies) => {
    return action$.pipe(
        filter(isActionOf(licensesActionsAsync.purchaseProduct.request)),
        switchMap(action => {
            return new Observable<RootAction>(observer => {
                const state = state$.value as IState;
                const { apiGatewayUrl, paymentApiKey } = state.constants;
                const user = state.users.user;
                async function purchaseProduct() {
                    const result = await dependencies.licensesApi.purchaseProduct({
                        apiGatewayUrl,
                        paymentApiKey,
                        product: {
                            ...action.payload,
                            ...(
                                user?.clientId && { client_id: user.clientId }
                                || user?.customerId && { customer_id: user.customerId }
                            ),
                            purchased_by: user?.username,
                        },
                    });
                    const redirect_url = result.redirect_url
                    observer.next(licensesActionsAsync.purchaseProduct.success({ redirect_url }));
                }
                purchaseProduct().catch(error => {
                    console.log(error);
                    observer.next(licensesActionsAsync.purchaseProduct.failure({ error }));
                });
            });
        })
    );
}