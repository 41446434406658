import cognitoApi from "./cognitoApi";
import usersApi from "./usersApi";
import clientsApi from "./clientsApi";
import formsApi from "./formsApi";
import reportsApi from "./reportsApi";
import analysesApi from "./analysesApi";
import licensesApi from "./licensesApi";

export const dependencies = {
    cognitoApi,
    usersApi,
    clientsApi,
    formsApi,
    reportsApi,
    analysesApi,
    licensesApi,
};